<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          DNS <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="newdns"
          >
            เพิ่ม DNS
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-text-field
              v-model="search"
              label="ค้นหา dns"
              dense
              :prepend-icon="icons.mdiMagnify"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-data-table
              :search="search"
              :headers="headers"
              :loading="isloading"
              :items="dnss"
              :items-per-page="25"
              class="elevation-1"
            >
              <template v-slot:[`item.proxied`]="{ item }">
                <v-switch
                  v-if="item.type !== 'TXT'"
                  v-model="item.proxied"
                  @change="toggleproxy(item)"
                ></v-switch>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-btn
                  icon
                  color="info"
                  @click="editcontentgroup(item)"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn> -->
                <v-btn
                  icon
                  color="error"
                  @click="removedns(item.id)"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">DNS</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="currentdns.name"
                  label="Name (ถ้าเป็น Root Domain ให้ใส่ @)"
                  dense
                  outlined
                  :rules="[v=> !!v || 'Required']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="currentdns.type"
                  :items="['A', 'TXT', 'CNAME']"
                  label="Type"
                  dense
                  outlined
                  :rules="[v=> !!v || 'Required']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="currentdns.targettype"
                  :items="['Input','Wallet' ]"
                  label="TargetType"
                  dense
                  outlined
                  :rules="[v=> !!v || 'Required']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="currentdns.targettype === 'Input'">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="currentdns.content"
                  label="Target"
                  dense
                  outlined
                  :rules="[v=> !!v || 'Required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            @click="adddns"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
  mdiPlusBox,
  mdiMinusBox,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiPlusBox,
        mdiMinusBox,
      },
    }
  },
  data() {
    return {
      covertmp: null,
      thumbnailtmp: null,
      search: '',
      isloading: false,
      findcontenttitle: '',
      dialog: false,
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Type', align: 'start', value: 'type' },
        { text: 'Target', align: 'start', value: 'content' },
        { text: 'HTTPS', align: 'start', value: 'proxied' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      contentgroups: [],
      contentgroup: '',
      mainpage: {

      },
      dnss: [],
      dialogmode: 'new',
      currentdns: {
        name: '',
        type: '',
        content: '',
        proxied: '',
      },
    }
  },
  watch: {
    async findcontenttitle(val) {
      if (this.contents.length > 0) return
      const { data: { contents } } = await this.axios.get('/admin/contents/title', {
        params: { title: val },
      })
      this.contents = contents
    },
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website
    this.getdnss()
  },
  methods: {
    async adddns() {
      if (this.currentdns.type === 'A' && this.currentdns.content === 'Wallet' && this.dnss.find(dns => dns.content === 'Wallet')) {
        alert('กรุณาลบ DNS ของ Wallet ปัจจุบัน')
      } else {
        await this.axios.post('/admin/dns', this.currentdns)
        this.getdnss()
      }
      this.dialog = false
    },
    async removedns(id) {
      if (confirm('ยืนยันการลบ DNS')) {
        await this.axios.delete('/admin/dns', { params: { id } })
        this.getdnss()
      }
    },
    async toggleproxy(item) {
      await this.axios.put('/admin/dns', item)
      this.getdnss()
    },
    async getdnss() {
      this.isloading = true
      const { data } = await this.axios.get('/admin/dnss')
      this.dnss = data.dns
      this.isloading = false
    },
    newdns() {
      this.dialog = true
    },
  },
}
</script>
